@tailwind base;
@tailwind components;
@tailwind utilities;

nav.has-scrolled {
  @apply bg-primary-base;
  @apply py-4;
  @apply text-base;
}

nav.has-scrolled #logo-container {
  @apply px-4;
  @apply py-6;
}

nav.has-scrolled #logo-container img {
  @apply h-6;
}

nav .active {
  @apply border-t-4 border-accent-base border-solid;
}

.link {
  @apply text-primary-base;
  @apply font-bold;
  @apply transition duration-200 ease-in-out;
  @apply decoration-accent-base;
  @apply hover:underline;
  @apply focus:underline;
}

.big-number {
  @apply bg-accent-base;
  @apply text-2xl;
  @apply text-white;
  @apply font-bold;
  @apply rounded-full;
  @apply h-12;
  @apply w-12;
  @apply inline-flex items-center justify-center;
}

.fade-in {
  @apply transition-all ease-in-out duration-1000;
  @apply opacity-0;
}

.menu-btn {
  cursor: pointer;
  transition: all 0.25s ease-out;
}

/* Styling the hamburger lines */
.menu-btn .btn-line {
  display: block;
  width: 28px;
  height: 3px;
  margin: 0 0 5px 0;
  background: white;
  transition: all 0.25s ease-out;
}

/* Adding transform to the X */
.menu-btn.close {
  transform: rotate(180deg);
}

/* Styling the three lines
to make it an X */
.menu-btn.close .btn-line:nth-child(2) {
  transform: rotate(45deg) translate(5px, 5px);
}

.menu-btn.close .btn-line:nth-child(3) {
  opacity: 0;
}

.menu-btn.close .btn-line:nth-child(4) {
  transform: rotate(-45deg) translate(7px, -6px);
}

.button {
  text-shadow: 1px 1px 2px rgb(0,0,0);
  @apply transition ease-in-out duration-200 text-white text-sm bg-primary-base hover:bg-accent-dark focus:bg-accent-dark px-8 py-4 font-bold tracking-wide;
}

.button:disabled {
  text-shadow: none;
  @apply bg-gray-200;
  @apply text-gray-500;
}

.help {
  @apply tracking-wide;
}

.help h2 {
  @apply font-bold mt-12 mb-2;
  @apply text-xl;
}

.help p {
  @apply py-2;
}


.help .toc h3 {
  @apply font-bold;
  @apply border-b;
}

.help .toc li {
  @apply py-2;
  @apply tracking-wide;
}

/*
.help .toc li:not(:first-child) {
  @apply border-t;
}
*/

.help .toc > div {
  @apply lg:sticky top-0;
  @apply pt-16;
}

.help .content ul {
  @apply list-disc ml-4;
}

.help ol {
  @apply list-decimal ml-4;
}

.help table {
  @apply my-4;
}

.help th, .help td {
  @apply p-2;
  @apply text-sm;
  @apply border border-light border-solid;
}

.help th[scope='row'] {
  @apply font-normal;
  @apply italic;
}

.help thead th {
  text-align: left;
}

.help figure {
  @apply bg-light;
  @apply p-4;
  @apply my-2;
  @apply rounded;
}

.help figure figcaption {
  @apply pt-2;
  @apply text-sm;
}

.help a {
  @apply link;
}

.help {
  @apply lg:grid grid-cols-3 gap-8;
}

.help h1 {
  @apply col-span-3;
}

.help > div {
  @apply col-span-2;
}

.help .important {
  @apply bg-yellow-100;
  @apply p-2;
  @apply my-4;
  @apply text-sm;
  @apply rounded;
}

.help .important strong {
  @apply text-yellow-900;
}

.help .note {
  @apply bg-blue-100;
  @apply p-2;
  @apply my-4;
  @apply text-sm;
  @apply rounded;
}

.help .note strong {
  @apply text-blue-900;
}

.help .tip {
  @apply bg-green-100;
  @apply p-2;
  @apply my-4;
  @apply text-sm;
  @apply rounded;
}

.help .tip strong {
  @apply text-green-900;
}
