*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: robotoregular, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

:-moz-focusring {
  outline:  auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.bottom-0 {
  bottom: 0;
}

.z-50 {
  z-index: 50;
}

.z-10 {
  z-index: 10;
}

.z-40 {
  z-index: 40;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-14 {
  height: 3.5rem;
}

.h-screen {
  height: 100vh;
}

.h-28 {
  height: 7rem;
}

.h-12 {
  height: 3rem;
}

.h-6 {
  height: 1.5rem;
}

.min-h-screen {
  min-height: 100vh;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-lg {
  max-width: 32rem;
}

.flex-1 {
  flex: 1;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.resize {
  resize: both;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-4 {
  gap: 1rem;
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1rem * var(--tw-space-y-reverse) );
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(2rem * var(--tw-space-y-reverse) );
}

.space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(3rem * var(--tw-space-y-reverse) );
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse) );
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse) );
}

.overflow-hidden {
  overflow: hidden;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-b {
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.border {
  border-width: 1px;
}

.border-t-4 {
  border-top-width: 4px;
}

.border-light {
  --tw-border-opacity: 1;
  border-color: rgba(244, 244, 244, var(--tw-border-opacity) );
}

.border-accent-base {
  --tw-border-opacity: 1;
  border-color: rgba(80, 158, 41, var(--tw-border-opacity) );
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity) );
}

.border-primary-light {
  --tw-border-opacity: 1;
  border-color: rgba(103, 166, 203, var(--tw-border-opacity) );
}

.bg-primary-base {
  --tw-bg-opacity: 1;
  background-color: rgba(42, 89, 142, var(--tw-bg-opacity) );
}

.bg-light {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 244, 244, var(--tw-bg-opacity) );
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity) );
}

.bg-accent-base {
  --tw-bg-opacity: 1;
  background-color: rgba(80, 158, 41, var(--tw-bg-opacity) );
}

.bg-primary-dark\/\[\.35\] {
  background-color: rgba(0, 48, 96, .35);
}

.bg-black\/\[0\.5\] {
  background-color: rgba(0, 0, 0, .5);
}

.bg-transparent {
  background-color: rgba(0, 0, 0, 0);
}

.bg-hero-error {
  background-image: url("hero-error.36b32b9b.jpg");
}

.bg-hero-about {
  background-image: url("hero-about.2230c381.jpg");
}

.bg-hero-home {
  background-image: url("hero-home.5a217e32.jpg");
}

.bg-hero-proposals {
  background-image: url("hero-proposals.67274ae2.jpg");
}

.bg-hero-search {
  background-image: url("hero-search.28ac6394.jpg");
}

.bg-hero-support {
  background-image: url("hero-support.d6fe9b0c.jpg");
}

.bg-cover {
  background-size: cover;
}

.bg-bottom {
  background-position: bottom;
}

.bg-center {
  background-position: center;
}

.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.p-8 {
  padding: 2rem;
}

.p-4 {
  padding: 1rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.pt-36 {
  padding-top: 9rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.leading-relaxed {
  line-height: 1.625;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity) );
}

.text-accent-dark {
  --tw-text-opacity: 1;
  color: rgba(34, 131, 0, var(--tw-text-opacity) );
}

.text-primary-base {
  --tw-text-opacity: 1;
  color: rgba(42, 89, 142, var(--tw-text-opacity) );
}

.text-accent-base {
  --tw-text-opacity: 1;
  color: rgba(80, 158, 41, var(--tw-text-opacity) );
}

.text-dark {
  --tw-text-opacity: 1;
  color: rgba(69, 69, 69, var(--tw-text-opacity) );
}

.text-red-900 {
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity) );
}

.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--tw-shadow);
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-\[0_3px_6px_rgba\(0\,0\,0\,0\.9\)\] {
  --tw-shadow: 0 3px 6px rgba(0, 0, 0, .9);
  --tw-shadow-colored: 0 3px 6px var(--tw-shadow-color);
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--tw-shadow);
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--tw-shadow);
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.outline-1 {
  outline-width: 1px;
}

.outline-primary-base {
  outline-color: #2a598e;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.duration-500 {
  transition-duration: .5s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.\[text-shadow\:1px_1px_2px_rgb\(0\,0\,0\)\] {
  text-shadow: 1px 1px 2px #000;
}

nav.has-scrolled {
  --tw-bg-opacity: 1;
  background-color: rgba(42, 89, 142, var(--tw-bg-opacity) );
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

nav.has-scrolled #logo-container {
  padding: 1.5rem 1rem;
}

nav.has-scrolled #logo-container img {
  height: 1.5rem;
}

nav .active {
  --tw-border-opacity: 1;
  border-style: solid;
  border-top-width: 4px;
  border-color: rgba(80, 158, 41, var(--tw-border-opacity) );
}

.link {
  --tw-text-opacity: 1;
  color: rgba(42, 89, 142, var(--tw-text-opacity) );
  font-weight: 700;
  -webkit-text-decoration-color: #509e29;
  text-decoration-color: #509e29;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.link:hover, .link:focus {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.big-number {
  --tw-bg-opacity: 1;
  background-color: rgba(80, 158, 41, var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity) );
  height: 3rem;
  width: 3rem;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
  display: inline-flex;
}

.fade-in {
  opacity: 0;
  transition-property: all;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.menu-btn {
  cursor: pointer;
  transition: all .25s ease-out;
}

.menu-btn .btn-line {
  width: 28px;
  height: 3px;
  background: #fff;
  margin: 0 0 5px;
  transition: all .25s ease-out;
  display: block;
}

.menu-btn.close {
  transform: rotate(180deg);
}

.menu-btn.close .btn-line:nth-child(2) {
  transform: rotate(45deg)translate(5px, 5px);
}

.menu-btn.close .btn-line:nth-child(3) {
  opacity: 0;
}

.menu-btn.close .btn-line:nth-child(4) {
  transform: rotate(-45deg)translate(7px, -6px);
}

.button {
  text-shadow: 1px 1px 2px #000;
  --tw-bg-opacity: 1;
  background-color: rgba(42, 89, 142, var(--tw-bg-opacity) );
  letter-spacing: .025em;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity) );
  padding: 1rem 2rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.25rem;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.button:hover, .button:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 131, 0, var(--tw-bg-opacity) );
}

.button:disabled {
  text-shadow: none;
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity) );
}

.help {
  letter-spacing: .025em;
}

.help h2 {
  margin-top: 3rem;
  margin-bottom: .5rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.help p {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.help .toc h3 {
  border-bottom-width: 1px;
  font-weight: 700;
}

.help .toc li {
  letter-spacing: .025em;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.help .toc > div {
  top: 0;
}

@media (min-width: 1024px) {
  .help .toc > div {
    position: -webkit-sticky;
    position: sticky;
  }
}

.help .toc > div {
  padding-top: 4rem;
}

.help .content ul {
  margin-left: 1rem;
  list-style-type: disc;
}

.help ol {
  margin-left: 1rem;
  list-style-type: decimal;
}

.help table {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.help th, .help td {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(244, 244, 244, var(--tw-border-opacity) );
  padding: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.help th[scope="row"] {
  font-style: italic;
  font-weight: 400;
}

.help thead th {
  text-align: left;
}

.help figure {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 244, 244, var(--tw-bg-opacity) );
  border-radius: .25rem;
  margin-top: .5rem;
  margin-bottom: .5rem;
  padding: 1rem;
}

.help figure figcaption {
  padding-top: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.help a {
  --tw-text-opacity: 1;
  color: rgba(42, 89, 142, var(--tw-text-opacity) );
  font-weight: 700;
  -webkit-text-decoration-color: #509e29;
  text-decoration-color: #509e29;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.help a:hover, .help a:focus {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.help {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2rem;
}

@media (min-width: 1024px) {
  .help {
    display: grid;
  }
}

.help h1 {
  grid-column: span 3 / span 3;
}

.help > div {
  grid-column: span 2 / span 2;
}

.help .important {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 249, 195, var(--tw-bg-opacity) );
  border-radius: .25rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.help .important strong {
  --tw-text-opacity: 1;
  color: rgba(113, 63, 18, var(--tw-text-opacity) );
}

.help .note {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity) );
  border-radius: .25rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.help .note strong {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity) );
}

.help .tip {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 252, 231, var(--tw-bg-opacity) );
  border-radius: .25rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.help .tip strong {
  --tw-text-opacity: 1;
  color: rgba(20, 83, 45, var(--tw-text-opacity) );
}

.placeholder\:italic::-moz-placeholder {
  font-style: italic;
}

.placeholder\:italic:-ms-input-placeholder {
  font-style: italic;
}

.placeholder\:italic::-ms-input-placeholder {
  font-style: italic;
}

.placeholder\:italic::placeholder {
  font-style: italic;
}

.hover\:border-t-4:hover {
  border-top-width: 4px;
}

.hover\:bg-primary-base:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(42, 89, 142, var(--tw-bg-opacity) );
}

.hover\:bg-accent-dark:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 131, 0, var(--tw-bg-opacity) );
}

.hover\:decoration-accent-light:hover {
  -webkit-text-decoration-color: #67cc35;
  text-decoration-color: #67cc35;
}

.hover\:shadow:hover {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--tw-shadow);
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.focus\:border-t-4:focus {
  border-top-width: 4px;
}

.focus\:border-primary-base:focus {
  --tw-border-opacity: 1;
  border-color: rgba(42, 89, 142, var(--tw-border-opacity) );
}

.focus\:bg-primary-base:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(42, 89, 142, var(--tw-bg-opacity) );
}

.focus\:bg-accent-dark:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 131, 0, var(--tw-bg-opacity) );
}

.focus\:shadow:focus {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--tw-shadow);
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.focus\:outline:focus {
  outline-style: solid;
}

.group:hover .group-hover\:ml-2 {
  margin-left: .5rem;
}

.group:hover .group-hover\:scale-105 {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.group:hover .group-hover\:bg-primary-base {
  --tw-bg-opacity: 1;
  background-color: rgba(42, 89, 142, var(--tw-bg-opacity) );
}

.group:hover .group-hover\:text-white, .group:focus .group-focus\:text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity) );
}

.peer:focus ~ .peer-focus\:text-primary-base {
  --tw-text-opacity: 1;
  color: rgba(42, 89, 142, var(--tw-text-opacity) );
}

@media (min-width: 640px) {
  .sm\:inline-block {
    display: inline-block;
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse) ) );
    margin-bottom: calc(0px * var(--tw-space-y-reverse) );
  }

  .sm\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse) );
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse) ) );
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

@media (min-width: 768px) {
  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:inline-block {
    display: inline-block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse) );
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse) ) );
  }

  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse) ) );
    margin-bottom: calc(0px * var(--tw-space-y-reverse) );
  }

  .md\:p-8 {
    padding: 2rem;
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .lg\:ml-12 {
    margin-left: 3rem;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:h-8 {
    height: 2rem;
  }

  .lg\:-translate-x-16 {
    --tw-translate-x: -4rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  }

  .lg\:translate-y-16 {
    --tw-translate-y: 4rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  }

  .lg\:translate-x-16 {
    --tw-translate-x: 4rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:gap-0 {
    gap: 0;
  }

  .lg\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse) ) );
    margin-bottom: calc(2rem * var(--tw-space-y-reverse) );
  }

  .lg\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse) );
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse) ) );
  }

  .lg\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .lg\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .lg\:opacity-0 {
    opacity: 0;
  }
}

/*# sourceMappingURL=index.4229925f.css.map */
